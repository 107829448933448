import NextLink from "next/link";

// @configs
import routes from "@configs/routes";

//  mui
import { Box, Typography, Button } from "@mui/material";

// block
import DestinationItem from '@views/destinations/DestinationsList/DestinationItem'

// @components
import Carousel from '@components/Carousel'

// types
import type { ResponseDestinationsList } from "@core/api/useEstate/types";


interface Props {
  data: ResponseDestinationsList['results']
}


export default function Destinations({ data }: Props) {

  return (
    <Box sx={{
      pb: 12,
    }}>

      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 5,
        pb: 5,
      }}>
        <Typography variant="h3" component="h2">
          Top Destinations
        </Typography>
        <Button
          LinkComponent={NextLink}
          href={routes.DESTINATIONS.INDEX}
          variant="outlined" color="primary">
          Show All Destinations
        </Button>
      </Box>

      <Box>

        <Carousel
          arrowsColor="primary"
          // arrowsOffsetTop="50%"
          rewind
          arrows
          perPage={4}
          gap={30}
          breakpoints={{
            xs: {
              perPage: 1,
              gap: 0,
            },
            sm: {
              perPage: 1,
              gap: 2,
            },
            md: {
              perPage: 3,
              gap: 2,
            },
            lg: {
              perPage: 4,
              gap: 5,
            },
            xl: {
              perPage: 4,
              gap: 5,
            },
          }}
          items={data.map((el, i: number) => (
            <DestinationItem key={i} data={el} />
          ))} />

      </Box>

    </Box>
  )
}