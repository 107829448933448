import NextLink from 'next/link';

// @configs
import routes from '@configs/routes';

// @hooks
import useMedia from '@hooks/useMedia';

// mui
import { Box, Typography, Link } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

// icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// types
import type { ResponsePageMain, CategoryItem } from "@core/api/useEstate/types";

interface Props {
  data: CategoryItem[]
}

export default function CustomImageList({ data }: Props) {

  // hooks
  const media = useMedia();
  const isMobile = media.down.md

  // computed
  const photos = data
  // const limit = 6
  // const gallery = data.map((item) => (item.photo))
  // const photos = data.filter((item, index) => index < limit);
  // const data = itemData.filter((item, index) => index < 4);


  const renderItem = (item: CategoryItem, src: string) => {

    const href = routes.CATEGORIES.CATEGORY.replace('[slug]', item.slug)

    return (
      <Link
        component={NextLink}
        href={href}
        color="common.white"
        sx={{

          position: 'relative',
          backgroundColor: 'grey.300',
          backgroundImage: `url(${src})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '100%',

          p: {
            xs: 5,
            md: 10,
          },

          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',

          textDecoration: 'none',
        }}>
        <Box
          component="span"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',

          }} />
        <Box
          component="span"
          sx={{
            position: 'relative',
            zIndex: 1,

            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',

            '& svg': {
              fontSize: 40,
            }
          }}>
          <Box component="span"
            sx={{
              typography: {
                xs: 'h5',
                md: 'h3',
              }
            }}>
            {item.name}
          </Box>
          <ArrowForwardIcon />
        </Box>
      </Link>
    )
  }


  return (
    <Box sx={{
      borderRadius: 2,
      overflow: 'hidden',
    }}>
      <ImageList
        sx={{
          width: '100%',
          // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
          transform: 'translateZ(0)',
          margin: 0,
        }}
        rowHeight={isMobile ? 130 : 240}
        cols={4}
        gap={5}
      >
        {photos.map((item, index) => {

          const isDouble = index % 3 === 0;
          let src = isDouble ? item.photo.medium : item.photo.small;

          let cols = isDouble ? 2 : 1;
          let rows = 1;

          if (isMobile) {
            src = item.photo.small;
            cols = 4;
          }

          return (
            <ImageListItem key={item.id} cols={cols} rows={rows}>

              {renderItem(item, src)}

            </ImageListItem>
          );
        })}
      </ImageList>
    </Box>
  );
}
