import NextLink from 'next/link'

// mui
import { Box, Typography, Link, Button } from "@mui/material";

// @core
import { usePropertyContext } from "@core/context";

// @hooks
import useMedia from '@hooks/useMedia';

// @components
import Carousel from '@components/Carousel'

// types
import type { ResponsePropertyItem } from "@core/api/useEstate/types";




function ListItem({ data }: { data: ResponsePropertyItem }) {

  // context
  const propertyContext = usePropertyContext()

  const makeTitle = () => {
    let res = []
    if (data.type) {
      res.push(data.type)
    }
    if (data.bedrooms) {
      res.push(`${data.bedrooms} bds`)
    }
    if (data.bathrooms) {
      res.push(`${data.bathrooms} ba`)
    }
    if (data.common_area) {
      res.push(propertyContext.calculateArea(data.common_area))
    }
    return res.join(' • ')
  }

  return (
    <Box>
      {/* <pre>
        {JSON.stringify(data, null, 2)}
      </pre> */}

      <Box
        component={NextLink}
        href={data.public_url}
        sx={{
          display: 'block',
          bgcolor: 'grey.100',
          backgroundImage: `url(${data.preview?.small})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          width: '100%',
          borderRadius: 2,
          mb: 5,
          cursor: 'pointer',
        }}>
        <Box sx={{
          paddingTop: '56.25%',
        }} />
      </Box>

      <Link
        component={NextLink}
        href={data.public_url}
        variant="h5"
        color="inherit"
        sx={{
          display: 'block',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}>
        {data.title}
      </Link>

      <Box sx={{
        py: 3,
      }}>
        <Typography variant="subtitle2" color="text.secondary">
          {data.location_name}
        </Typography>
        <Typography variant="subtitle2" color="text.secondary">
          {makeTitle()}
        </Typography>
      </Box>

      {data.price > 0 ? (
        <Typography variant="h5">
          {propertyContext.calculatePrice(data.price)}
        </Typography>
      ) : ''}

    </Box>
  )
}

interface Props {
  title: string
  data: ResponsePropertyItem[]
  showAllHref: string
}

export default function Propertylist({ title, data, showAllHref }: Props) {

  // hooks
  const media = useMedia()

  // render
  if (data.length === 0) {
    return (<div />)
  }

  return (
    <Box sx={{
      bgcolor: 'background.paper',
      borderRadius: 2,
      p: 10
    }}>

      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 5,
        pb: 5,
      }}>
        <Typography variant="h5" component="h2" color="text.secondary">
          {title}
        </Typography>
        <Button
          href={showAllHref}
          LinkComponent={NextLink}
          variant="outlined" color="primary">
          Show All
        </Button>
      </Box>

      <Box>
        <Carousel
          arrowsColor="primary"
          arrowsOffsetTop="30%"
          rewind
          arrows
          perPage={4}
          gap={30}
          breakpoints={{
            xs: {
              perPage: 1,
              gap: 0,
            },
            sm: {
              perPage: 1,
              gap: 2,
            },
            md: {
              perPage: 3,
              gap: 2,
            },
            lg: {
              perPage: 4,
              gap: 5,
            },
            xl: {
              perPage: 4,
              gap: 5,
            },
          }}
          items={data.map((el, i: number) => (
            <ListItem key={i} data={el} />
          ))} />

      </Box>

    </Box>
  )
}