
// @core
import { useGlobalContext } from "@core/context";

// @configs
import routes from "@configs/routes";

// mui
import { Box, Container, Stack } from "@mui/material";

// snippets
import Propertylist from '@snippets/Propertylist'


// blocks
import Header from './Header'
import Categories from './Categories'
import CategoriesPrice from './CategoriesPrice'
import Destinations from './Destinations'
import Complexes from './Complexes'
// import Promo from './Promo'
import Articles from './Articles'

// types
import type { ResponsePageMain } from "@core/api/useEstate/types";


export default function View() {

  // context
  const globalContext = useGlobalContext()

  // computed
  const pageData: ResponsePageMain = globalContext.pageProps.pageData || null
  const propertyLatest = pageData?.property_latest || null
  const propertyCheapest = pageData?.property_cheapest || null
  const categoriesDestinations = pageData?.categories_destinations || null
  const categoriesPrice = pageData?.categories_price || null


  return (
    <Box>

      <Header />

      <Box sx={{
        pt: 10,
      }}>
        <Stack spacing={10}>

          <Box>
            <Container maxWidth="xl">

              <Stack spacing={10}>

                <Propertylist
                  title="Latest Caribbean Real Estate Listings"
                  data={propertyLatest}
                  showAllHref={routes.CATALOG.INDEX}
                />

                <Categories data={categoriesDestinations} />

                <Complexes data={pageData.complexes} />

                <CategoriesPrice data={pageData.categories_price} />

                <Destinations data={pageData.destinations} />

              </Stack>

            </Container>
          </Box>

          {/* <Promo /> */}

          <Box>
            <Container maxWidth="xl">

              <Stack spacing={10}>
                <Propertylist
                  title="Cheapest Caribbean Real Estate Listings"
                  data={propertyCheapest}
                  showAllHref={routes.CATALOG.INDEX}
                />

                <Articles
                  data={pageData.feed}
                />

              </Stack>

            </Container>
          </Box>

        </Stack>

      </Box>

    </Box>
  )
}