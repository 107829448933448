
// @configs
import routes from "@configs/routes";

// mui
import { Box, Typography, Link, Chip } from "@mui/material";

// @components
import Carousel from '@components/Carousel'
import { ButtonLink } from "@components/Link";

// @hooks
import useMedia from '@hooks/useMedia';

// types
import type { ResponsePageMainFeed } from "@core/api/useEstate/types";


const ListItem = ({ item }: { item: ResponsePageMainFeed }) => {
  return (
    <Box>
      <Box
        component={Link}
        href={item.link}
        sx={{
          display: 'block',

          width: '100%',
          backgroundImage: `url(${item.data.image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: 2,

          position: 'relative',
          mb: 5,
        }}>
        <Box sx={{
          paddingTop: '56.25%',
        }} />
        <Box sx={{
          position: 'absolute',
          zIndex: 1,
          left: 0,
          bottom: 0,
          p: 3,
        }}>
          <Chip size="small" variant="filled" color="secondary"
            label={item.published} />
        </Box>
      </Box>



      <Link
        color="inherit"
        href={item.link}
        variant="h6"
        gutterBottom sx={{
          display: 'block',
        }}>
        {item.title}
      </Link>
      <Typography variant="subtitle2" color="text.secondary">
        {item.data.text}
      </Typography>
    </Box>
  )
}

interface Props {
  data: ResponsePageMainFeed[]
}

export default function Articles({ data }: Props) {

  // hooks
  const media = useMedia()

  return (
    <Box>

      <Box sx={{
        p: 10,
        bgcolor: 'background.paper',
        borderRadius: 2,
      }}>

        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 5,
          pb: 5,
        }}>
          <Typography variant="h5" component="h2" color="text.secondary">
            Caribbean News and Articles
          </Typography>
          <ButtonLink
            href={routes.PAGES.BLOG}
            variant="outlined" color="primary">
            Show All
          </ButtonLink>
        </Box>

        <Carousel
          arrowsColor="primary"
          arrowsOffsetTop="30%"
          rewind
          arrows
          perPage={4}
          gap={30}
          breakpoints={{
            xs: {
              perPage: 1,
              gap: 0,
            },
            sm: {
              perPage: 1,
              gap: 2,
            },
            md: {
              perPage: 3,
              gap: 2,
            },
            lg: {
              perPage: 4,
              gap: 5,
            },
            xl: {
              perPage: 4,
              gap: 5,
            },
          }}
          items={data.map((el, i: number) => (
            <ListItem key={i} item={el} />
          ))} />

      </Box>

    </Box>
  )
}