// @configs
import cdn from '@configs/cdn'

// mui
import { keyframes } from '@mui/material';
import { Box, Container, Typography } from "@mui/material";

// snippets
import SearchPanel from '@views/catalog/SearchPanel'

// const slideFromLeft = keyframes`
//   from {
//     opacity: 0;
//     transform: translateX(-100%);
//   }
//   to {
//     opacity: 1;
//     transform: translateX(0);
//   }
// `
// const slideFromDown = keyframes`
//   from {
//     opacity: 0;
//     transform: translateY(100%);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }
`
// const scaleBackground = keyframes`
//   from {
//     transform: scale(1);
//   }
//   to {
//     transform: scale(1.2);
//   }
// `
// const underlineAnimation = keyframes`
//   0% {
//     opacity: 0;
//     width: 0;
//   }
//   100% {
//     opacity: 1;
//     width: 100%;
//   }
// `


export default function Header() {

  const randomIndex = Math.floor(Math.random() * cdn.mainBg.length);
  const randomBg = cdn.mainBg[randomIndex];
  // const randomBg = cdn.mainBg[0];

  return (
    <Box sx={{
      position: 'relative',
    }}>

      <Box sx={{
        bgcolor: 'grey.900',
        minHeight: 'calc(90vh - 200px)',
        py: 10,
        pb: 30,
        position: 'relative',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

        color: 'common.white',
      }}>

        <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: 'hidden',
        }}>
          <Box sx={{
            width: '100%',
            height: '100%',
            backgroundImage: `url(${randomBg})`,
            backgroundSize: 'cover',
            backgroundClip: 'content-box',

            backgroundPosition: 'bottom center',
            // animation: `${scaleBackground} 25s infinite alternate`,
          }} />
        </Box>

        <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          bgcolor: 'rgba(0,0,0,0.5)'
        }} />

        <Container maxWidth="xl">

          <Box sx={{
            position: 'relative',
            zIndex: 1,
            maxWidth: {
              xs: '80%',
              md: 1100,
            },
            // pt: 20,
            // pb: 30,
            mx: {
              xs: 'auto',
              md: 0,
            },
            textAlign: {
              xs: 'center',
              md: 'left',
            }
          }}>
            <Typography variant="h1" component="h1" sx={{
              mb: 8,
              fontSize: {
                xs: '2rem',
                md: '3.5rem'
              },
              lineHeight: 1.05,
              // opacity: 0,
              // animation: `${slideFromLeft} 1s ease forwards`,
              // animationDelay: '0.5s',
              textShadow: '0 0 5px rgba(0,0,0,0.5)',
            }}>
              #1 Source for Real Estate <br />in the Caribbean
              {/* Real estate in the{' '}
              <Box
                component="span"
                className="highlight"
                sx={{
                  position: 'relative',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    left: 0,
                    bottom: '1px',
                    width: '100%',
                    height: '7px',
                    borderRadius: 1,
                    bgcolor: 'success.main',
                    opacity: 0,
                    animation: `${underlineAnimation} 2s ease forwards`,
                    animationDelay: '2.5s',
                  },
                }}
              >
                Caribbean
              </Box>{' '}
              for living and investment */}
            </Typography>

            <Typography variant="h2" component="h2" sx={{
              fontSize: {
                xs: '1.3rem',
                md: '1.8rem'
              },
              // opacity: 0,
              // animation: `${slideFromLeft} 1s ease forwards`,
              // animationDelay: '1s',
              textShadow: '0 0 5px rgba(0,0,0,0.5)',
            }}>
              {/* Find your dream home, vacation rental or heirloom quality land to build on. */}
              Let us help you find your dream home, vacation rental or investment property across Central America and the Caribbean region.
            </Typography>

          </Box>

        </Container>

      </Box>

      <Box sx={{
        position: 'relative',
        marginTop: '-90px',
        zIndex: 1,
      }}>
        <Container maxWidth="xl">
          <Box sx={{
            bgcolor: 'background.paper',
            boxShadow: {
              xs: 3,
              md: 6,
            },
            borderRadius: 2,
            // border: '5px solid',
            // borderColor: 'divider',
            p: 10,
            pt: 8,
            // opacity: 0,
            // animation: `${slideFromDown} 1s ease forwards`,
            // animationDelay: '1.3s',
          }}>
            <Typography
              sx={{
                typography: {
                  xs: 'h6',
                  md: 'h5',
                },
                textAlign: {
                  xs: 'center',
                  md: 'left',
                },
                mb: 6,
              }}>
              Search for Real Estate in the Caribbean
            </Typography>
            <SearchPanel expanded={true} />
          </Box>
        </Container>
      </Box>

    </Box>
  )
}