import NextLink from "next/link";

// @configs
import routes from "@configs/routes";

// mui
import { Box, Typography, Button } from "@mui/material";

// blocks
import Gallery from './Gallery'

// types
import type { CategoryItem } from "@core/api/useEstate/types";


interface Props {
  data: CategoryItem[]
}

export default function Categories({ data }: Props) {

  if (!data) return null

  // const galleryData = data.map((item) => (item.photo))

  return (
    <Box>

      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 5,
        pb: 5,
      }}>
        <Typography variant="h3" component="h2">
          Browse Caribbean Real Estate by Category
        </Typography>
        <Button
          LinkComponent={NextLink}
          href={routes.CATEGORIES.INDEX}
          variant="outlined" color="primary">
          Show All
        </Button>
      </Box>

      <Gallery data={data} />

    </Box>
  )
}