
// mui
import { useTheme, Theme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { Box } from '@mui/material';

// icons
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// libs
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import type { Options } from '@splidejs/splide';
import '@splidejs/react-splide/css';


type BreakpointSize = {
  perPage: number;
  gap: number;
  height?: string | number;
  offsetTop?: number | string;
};

interface Props extends Options {
  items: JSX.Element[]

  arrowsOffsetTop?: string | number
  arrowsColor?: 'primary' | 'secondary'

  gap: number
  perPage: number

  breakpoints: {
    xs?: BreakpointSize;
    sm?: BreakpointSize;
    md?: BreakpointSize;
    lg?: BreakpointSize;
    xl?: BreakpointSize;
  };
}



export default function Slider({ items, arrowsColor, ...props }: Props) {

  // hooks
  const theme = useTheme<Theme>()
  // const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  // const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  // const isMd = useMediaQuery(theme.breakpoints.only('md'));
  // const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  // const isXl = useMediaQuery(theme.breakpoints.up('xl'));

  const bgcolor = arrowsColor ? theme.palette[arrowsColor].main : theme.palette.primary.main
  const hoverBgcolor = arrowsColor ? `${arrowsColor}.dark` : 'primary.dark'
  const color = arrowsColor ? `${arrowsColor}.contrastText` : 'primary.contrastText'


  const renderArrow = (direction: 'prev' | 'next') => {
    return (
      <div
        className={`splide__arrow splide__arrow--${direction}`}
        style={{
          position: 'absolute',
          top: props.arrowsOffsetTop || '50%',
          zIndex: 1,
        }}
      >
        <ChevronRightIcon color="inherit" />
      </div>
    )
  }

  const values: any = theme.breakpoints.values
  const breakpoints = Object.entries(props.breakpoints).reduce((acc, [key, value]) => {
    const keyValue = values[key] as number
    acc[keyValue] = {
      key,
      perPage: value.perPage,
      gap: theme.spacing(value.gap),
      height: value.height,
    }
    return acc
  }, {} as any)


  if (!items?.length) return null

  return (

    <Box sx={{
      '& .splide__arrow': {
        bgcolor: bgcolor,
        border: '1px solid',
        borderColor: color,
        opacity: 1,
        color: color,
        transition: 'all .3s ease',
        '&:hover': {
          bgcolor: hoverBgcolor,
          // color: color,
          transform: `translateY(-50%) scale(1.1)`,
        },
        '& svg': {
          fill: 'currentColor',
        },
        '&.splide__arrow--prev': {
          // left: theme => arrowsOffset ? theme.spacing(arrowsOffset) : 0,
        },
        '&.splide__arrow--next': {
          // right: theme => arrowsOffset ? theme.spacing(arrowsOffset) : 0,
        },
      },
    }}>
      {/* <pre>
        {JSON.stringify(breakpoints, null, 2)}
      </pre> */}
      <Splide
        hasTrack={false}
        options={{
          arrows: false,
          rewind: true,
          pagination: false,
          autoHeight: true,
          padding: {
            bottom: 0,
          },
          ...props,
          breakpoints,
        }}>

        <SplideTrack>
          {items.map((item, index) => (
            <SplideSlide key={index}>
              {item}
            </SplideSlide>
          ))}
        </SplideTrack>

        <Box className="splide__arrows">
          {renderArrow('prev')}
          {renderArrow('next')}
        </Box>

      </Splide>
    </Box>
  );
}