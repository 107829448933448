import NextLink from "next/link";

// @configs
import routes from "@configs/routes";

// @core
import { usePropertyContext } from "@core/context";

//  mui
import { Box, Typography, Button } from "@mui/material";

// @components
import Carousel from '@components/Carousel'

// types
import type { ResponsePageMain } from "@core/api/useEstate/types";


interface Props {
  data: ResponsePageMain['complexes']
}


export default function Complexes({ data }: Props) {

  return (
    <Box sx={{
      pb: 12,
    }}>

      {/* <pre>
        {JSON.stringify(data, null, 2)}
      </pre> */}

      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 5,
        pb: 5,
      }}>
        <Typography variant="h3" component="h2">
          New Communities
        </Typography>
        <Button
          LinkComponent={NextLink}
          href={routes.CATALOG.COMPLEXESES.INDEX}
          variant="outlined" color="primary">
          Show All
        </Button>
      </Box>

      <Box>

        <Carousel
          arrowsColor="primary"
          arrowsOffsetTop="115px"
          rewind
          arrows
          perPage={4}
          gap={30}
          breakpoints={{
            xs: {
              perPage: 1,
              gap: 0,
            },
            sm: {
              perPage: 1,
              gap: 2,
            },
            md: {
              perPage: 3,
              gap: 2,
            },
            lg: {
              perPage: 4,
              gap: 5,
            },
            xl: {
              perPage: 4,
              gap: 5,
            },
          }}
          items={data.map((el, i: number) => (
            <ComplexCard key={i} data={el} />
          ))} />

      </Box>

    </Box>
  )
}

const ComplexCard = ({ data }: { data: Props['data'][0] }) => {

  // context
  const propertyContext = usePropertyContext()

  // computed
  const href = routes.CATALOG.COMPLEXESES.COMPLEX.replace('[slug]', data.slug)

  return (
    <Box
      component={NextLink}
      href={href}
      sx={{
        textDecoration: 'none',
        color: 'text.primary',

        // '&:hover': {
        //   '& [data-arrow]': {
        //     opacity: 1,
        //     transform: 'translateX(40%)',
        //   }
        // }
      }}>
      <Box
        component="img"
        src={data.preview?.small || ''}
        sx={{
          width: '100%',
          height: 230,
          objectFit: 'cover',
          borderRadius: 2,
          mb: 5,
        }} />
      <Typography variant="h5" component="h3" gutterBottom color="inherit">
        {data.title}
      </Typography>
      <Typography variant="h6" color="inherit">
        {propertyContext.calculatePrice(data.price)}
      </Typography>
    </Box>
  )
}