import NextLink from "next/link";

// @configs
import routes from "@configs/routes";

// mui
import { Box, Typography } from "@mui/material";

// icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


// types
import type { CategoryItem } from "@core/api/useEstate/types";


interface Props {
  data: CategoryItem
}

export default function ListItem({ data }: Props) {

  const href = routes.CATEGORIES.CATEGORY.replace('[slug]', data.slug)

  return (
    <Box
      component={NextLink}
      href={href}
      sx={{
        backgroundColor: 'background.paper',
        backgroundImage: data.photo ? `url(${data.photo.medium})` : undefined,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: 2,
        height: {
          xs: 200,
          sm: 300,
          md: 400,
          lg: 500,
        },
        p: 5,
        color: 'common.white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        position: 'relative',
        overflow: 'hidden',
        textDecoration: 'none',
        // '&:hover': {
        //   '& [data-arrow]': {
        //     opacity: 1,
        //     transform: 'translateX(40%)',
        //   }
        // }
      }}>

      <Box
        component="span"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0,0,0,0.5)',
          // opacity: 1,
          transition: 'all 0.3s ease',
          // '&:hover': {
          //   opacity: .8,
          // }
        }} />

      <Box
        component="span"
        sx={{
          position: 'relative',
          zIndex: 1,

          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}>
        <Box component="span">
          <Typography variant="h4">
            {data.name}
          </Typography>
        </Box>
        <Box
          data-arrow
          sx={{
            pr: 5,
            // opacity: 0,
            // transition: 'all 0.3s ease',
            '& svg': {
              fontSize: 40,
            }
          }}>
          <ArrowForwardIcon color="inherit" />
        </Box>
      </Box>
    </Box>
  )
}