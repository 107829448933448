// views
import View from '@views/main'
export default View


// i18n
import { getServerSidePropsI18n, GetServerSidePropsContext } from "@core/i18n/pageService/getServerPropsI18n"
import { tDict } from '@core/i18n/useTranslate'

// @core
import { useEstate } from '@core/api'


export const getServerSideProps = async (context: GetServerSidePropsContext) => {

  const estate = useEstate()
  const pageData = await estate.PAGES.main().then((res) => {
    if (res.status === 200) {
      return res.data
    }
    return null
  })

  return getServerSidePropsI18n(context,
    {
      pageData,
      config: {
        protected: false,
        appBar: {
          position: 'relative',
          // bgcolor: 'transparent',
          noContainer: true,
        },
        footer: {
          size: 'default'
        },
        plugins: {
          chat: true,
        },
        seo: {
          title: 'Real estate in the Caribbean for living and investment for Buying Luxury Properties and Houses',
          description: 'Find your dream home, vacation rental or heirloom quality land to build on.',
        },
      },
    },
    {
      namespacesRequired: ['main']
    }
  );
};
